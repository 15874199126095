<template>
  <v-container id="category" fluid tag="section">
    <v-alert color="red lighten-2" dark v-if="showError">
      {{ showErrorData }}
    </v-alert>
    <v-form ref="form">
      <v-container class="py-2">
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field
              label="Name"
              class="purple-input"
              v-model="name"
              :rules="inputRule"
              :disabled="onlyView"
            />
          </v-col>
          <v-col cols="6" md="6">
            <v-select
              :items="positions"
              label="Banner Position"
              class="purple-input"
              v-model="position"
              :rules="inputRule"
              :disabled="onlyView"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="dateRange"
                  label="Select Banner Duration Dates"
                  prepend-icon="mdi-calendar"
                  readonly
                  :disabled="onlyView"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateRange"
                range
                change="dateRangeChange"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6" md="6">
            <!-- <v-file-input
              show-size
              label="Images"
              counter
              :rules="fileuploadSize"
              truncate-length="15"
              @change="selectFile()"
              v-model="file"
              :disabled="onlyView"
              accept="image/*"
            ></v-file-input> -->
            <v-file-input
              show-size
              label="Images"
              counter
              truncate-length="15"
              @change="selectFile()"
              v-model="file"
              :disabled="onlyView"
              accept="image/*"
            ></v-file-input>
            <ImageErrorDialog
              :error-dialog="errorDialog"
              :error-message="errorMessage"
              @close="errorDialog = false"
            ></ImageErrorDialog>
            <div v-if="onlyView || editBanner">
              <v-col style="padding-left: 30px">
                <v-avatar>
                  <v-img
                    aspect-ratio="1"
                    :src="imageUrl + image"
                    :lazy-src="imageUrl + image"
                    @click="openNewTab(imageUrl + image)"
                    style="cursor: pointer"
                  />
                </v-avatar>
                <v-btn
                  icon
                  x-small
                  rounded
                  color="error"
                  style="background-color: #fff !important"
                  @click="deleteImage(image)"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </v-col>
              <!-- <v-avatar>
                <v-img
                  aspect-ratio="1"
                  :src="imageUrl + image"
                  :lazy-src="imageUrl + image"
                  @click="deleteImage(image)"
                  style="cursor: pointer"
                />
              </v-avatar> -->
              <v-dialog v-model="imageDelete" max-width="500px">
                <v-card>
                  <v-card-title
                    >Are you sure you want to delete image?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary darken-1"
                      depressed
                      @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="primary darken-1"
                      text
                      @click="deleteItemConfirm"
                      >Delete</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
          <!-- <v-col cols="6" md="6">
            <v-checkbox
              @change="selectPopup"
              v-model="isPopupAd"
              :label="`isPopupAds`"
              value="true"
              color="#cf9602"
              class="pb-5 pl-2"
              hide-details
              :disabled="onlyView"
            ></v-checkbox>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12" class="text-left">
            <v-btn
              type="button"
              color="primary"
              class="mr-0"
              @click="submit"
              :loading="loading"
            >
              {{
                onlyView == true
                  ? "Back"
                  : editBanner == true
                  ? "Update"
                  : "Add"
              }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import ImageErrorDialog from "@/views/dashboard/components/modals/ImageErrorDialog.vue";

export default {
  props: ["id", "action"],
  components: {
    ImageErrorDialog
  },
  data() {
    return {
      editBanner: null,
      onlyView: false,
      name: "",
      position: "",
      inputRule: [v => !!v || "Field is required"],
      file: null,
      imageDelete: false,
      fileuploadSize: [
        v => !v || v.size < 25000 || "Image Size should be less than 25 KB!"
      ],
      image: "",
      imageUrl: process.env.VUE_APP_Image_Link + "Banner/",
      sendFiles: "",
      loading: false,
      showError: false,
      showErrorData: null,
      positions: ["Top", "Bottom"],
      menu: false,
      dateRange: [],
      errorDialog: false,
      errorMessage: "",
      isPopupAd: false,
      isVideo: false
    };
  },
  methods: {
    getBannerData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "banner/edit/" + id)
        .then(response => {
          if (response.status == 200) {
            this.name = response.data.banner.name;
            this.position = response.data.banner.position;
            this.dateRange = response.data.banner.dateRange;
            this.image = response.data.banner.image;
            this.isPopupAd =
              response.data.banner.isPopupAd == true ? "true" : false;
            this.isVideo = response.data.banner.isVideo;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    openNewTab(url) {
      window.open(url, "_blank");
    },
    selectFile() {
      console.log("this.file : ", this.file);
      this.sendFiles = this.file;
      const maxSizeInBytes = 25 * 1024 * 1024; // 25MB
      const files = this.file;

      if (files) {
        const fileType = files.type.split("/")[0];
        if (fileType == "image") {
          this.isVideo = false;
        } else if (fileType == "video") {
          this.isVideo = true;
        }

        if (files.size > maxSizeInBytes && fileType == "image") {
          // this.errorMessage = `File '${files.name}' exceeds the maximum size limit (50kb).`;
          this.errorMessage = `Please choose an image with size less than 25 MB.`;
          this.errorDialog = true;
          this.file = []; // Clear the selected files
          return;
        }
      }
    },
    closeDelete() {
      this.imageDelete = false;
    },
    deleteItemConfirm() {
      let data = { id: this.id, name: this.categoryImageDelete };
      axios
        .post(process.env.VUE_APP_API_BASE_URL + "banner/delete/image", data)
        .then(response => {
          if (response.status == 200) {
            this.closeDelete();
            this.getBannerData(this.id);
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    submit() {
      this.showError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = new FormData();
        if (this.dateRange.length > 0) {
          this.dateRange.sort(function(a, b) {
            var c = new Date(a);
            var d = new Date(b);
            return c - d;
          });
        }
        formData.append("id", this.id);
        formData.append("name", this.name);
        formData.append("image", this.sendFiles);
        formData.append("position", this.position);
        formData.append("dateRange", this.dateRange);
        formData.append(
          "isPopupAd",
          this.isPopupAd != false ? true : this.isPopupAd
        );
        formData.append("isVideo", this.isVideo);

        if (this.onlyView) {
          this.$router.push({ name: "Banner" });
        } else if (this.editBanner == true) {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "banner/update", formData)
            .then(response => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "Banner",
                  params: { edit: true }
                });
              }
            })
            .catch(error => {
              this.loading = false;
              this.showError = true;
              this.showErrorData = error.response.data.name[0];
            });
        } else {
          axios
            .post(process.env.VUE_APP_API_BASE_URL + "banner/create", formData)
            .then(response => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "Banner",
                  params: { add: true }
                });
              }
            })
            .catch(error => {
              this.loading = false;
              this.showError = true;
              this.showErrorData = error.response.data.message;
            });
        }
      }
    },
    selectPopup() {
      this.isPopupAd = this.isPopupAd != null ? this.isPopupAd : false;
    }
  },
  mounted() {
    if (this.id != undefined) {
      this.getBannerData(this.id);
      this.editBanner = true;
    } else {
      this.editBanner = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  }
};
</script>

<style></style>
